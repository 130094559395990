/** @jsx jsx */
import { ReactElement } from 'react';
import { Box, Flex, jsx, ThemeUIStyleObject } from 'theme-ui';
import { CTA } from '@sprinklr/shared-lib/@types/entities';
import { CallToAction } from '@sprinklr/shared-lib';

export default function HeroCTAs({
  ctas,
  isHeroContentCascaded,
  isSingleHeroCentered,
  containerSx = {},
}: {
  ctas: CTA[];
  isHeroContentCascaded?: boolean;
  isSingleHeroCentered?: boolean;
  containerSx?: ThemeUIStyleObject;
}): ReactElement {
  return (
    <Box
      sx={{
        width: '100%',
        display: ['flex', 'block'],
        alignItems: 'center',
        justifyContent: 'center',
        ...containerSx,
      }}
    >
      <Flex
        sx={{
          display: ['inline-block', 'flex'], // inline block allows width to expand to largest child el & buttons to be equal width on mobile
          flexWrap: 'wrap',
          flexDirection: ['column', 'row'],
          minWidth: isHeroContentCascaded ? ['auto'] : undefined, // prevents button from wrapping
          justifyContent: [
            'center',
            'center',
            'center',
            isSingleHeroCentered ? 'center' : 'flex-start',
          ],
          alignItems: 'center',
          gap: '20px',
        }}
      >
        {ctas.map((cta, index) => {
          const isLast = ctas.length - 1 === index;
          const hasMoreThanOneCta = ctas.length > 1;
          return (
            <Box
              key={cta.text}
              sx={{
                marginTop: ['16px', '24px', '24px'],
                width: ['100%', 'auto'],
              }}
            >
              <CallToAction ctaSx={{ width: ['100%', 'auto'] }} {...cta} />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
}
