/** @jsx jsx */
import { ReactElement, Fragment, useMemo } from 'react';
import {
  jsx,
  Heading,
  Flex,
  Box,
  ThemeProvider,
  ThemeUIStyleObject,
  Theme,
} from 'theme-ui';
import { CTA, RichTextObject } from '@sprinklr/shared-lib/@types/entities';
import HeroHeadlines from './HeroHeadlines';
import HeroCTAs from './HeroCTAs';
import { HeroTags } from './heroTags/HeroTags';
import { RichText } from '@sprinklr/shared-lib';
import IconList from './IconsList';

import { IconListItem, Tag } from '../types';

const AligmentOptions = {
  LEFT: ['center', 'left'],
  RIGHT: ['center', 'right'],
  CENTER: ['center'],
  ALWAYS_LEFT: ['left'],
};

const HEADLINE_STYLES: ThemeUIStyleObject = {
  color: 'pageType',
  fontSize: [1, 2],
  letterSpacing: '1.2px',
  mt: ['10px', 0],
  fontFamily: 'body',
  fontWeight: 'bold',
  marginBottom: [3, '20px'],
  textTransform: 'uppercase',
};

const HERO_CONTENT_HTML_CONTAINER_STYLES: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: ['center', null, null, 'flex-start'],
};

const CATEGORY_LABEL_THEME_STYLES: Theme = {
  styles: {
    h1: HEADLINE_STYLES,
    h2: HEADLINE_STYLES,
  },
};

const HeroContentLabel = ({
  categoryLabelRichText,
}: {
  categoryLabelRichText: RichTextObject;
}): ReactElement => {
  return (
    <ThemeProvider theme={CATEGORY_LABEL_THEME_STYLES}>
      <RichText richTextObject={categoryLabelRichText} />
    </ThemeProvider>
  );
};

export default function HeroContent({
  headline,
  subHeadline,
  mobileHeadline,
  mobileSubHeadline,
  variant,
  isXLHeadlineSize,
  tags,
  ctas,
  isCascadedHeroContent,
  heroContentSx,
  isSingleHeroCentered,
  textAlignment,
  showDivider,
  categoryLabelRichText,
  reverseStackingOrder,
  heroContentHtml = '',
  iconList,
}: {
  headline?: RichTextObject;
  subHeadline?: RichTextObject;
  mobileHeadline?: RichTextObject;
  mobileSubHeadline?: RichTextObject;
  tags?: Tag[];
  ctas?: CTA[];
  isXLHeadlineSize?: boolean;
  isCascadedHeroContent?: boolean;
  variant?: string;
  heroContentSx?: any;
  contentFlex?: number;
  isSingleHeroCentered?: boolean;
  reverseStackingOrder?: boolean;
  textAlignment?: string;
  showDivider?: boolean;
  categoryLabelRichText?: RichTextObject;
  heroContentHtml?: string;
  iconList?: IconListItem[];
}): ReactElement {
  const trimHeadlineBottomMargin = ![subHeadline, ctas].some(Boolean);
  const trimSubheadlineBottomMargin = !ctas;

  const htmlObject = useMemo(
    () =>
      heroContentHtml
        ? {
            __html: heroContentHtml,
          }
        : null,
    [heroContentHtml],
  );

  const ctaEl =
    ctas?.length > 0 ? (
      <HeroCTAs
        ctas={ctas}
        isHeroContentCascaded={isCascadedHeroContent}
        isSingleHeroCentered={isSingleHeroCentered}
      />
    ) : null;
  let footerEl;
  if (showDivider) {
    footerEl = (
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          justifyContent: ['center', null, null, 'start'],
        }}
      >
        {ctaEl ? <Box sx={{ flex: 'none' }}>{ctaEl}</Box> : null}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: '1 1 0%',
            minWidth: '0%',
            marginTop: ['16px', '24px', '24px'],
          }}
        >
          <Box
            sx={{ height: '4px', backgroundColor: 'primary', width: '100%' }}
          ></Box>
        </Box>
      </Box>
    );
  } else {
    footerEl = ctaEl;
  }

  return (
    <Flex
      sx={{
        alignItems: isSingleHeroCentered ? 'center' : null,
        textAlign: isSingleHeroCentered
          ? 'center'
          : AligmentOptions[textAlignment] || [
              'center',
              'center',
              'center',
              'initial',
            ],
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        ...heroContentSx,
      }}
    >
      {heroContentHtml ? (
        <Box
          sx={HERO_CONTENT_HTML_CONTAINER_STYLES}
          dangerouslySetInnerHTML={htmlObject}
        ></Box>
      ) : (
        <Fragment>
          <HeroContentLabel categoryLabelRichText={categoryLabelRichText} />
          {headline && (
            <HeroHeadlines
              text={headline}
              mobileText={mobileHeadline}
              isXLHeadlineSize={isXLHeadlineSize}
              trimTextBottomMargin={trimHeadlineBottomMargin}
              className="hero-content-headline"
            />
          )}
          {subHeadline && (
            <HeroHeadlines
              text={subHeadline}
              mobileText={mobileSubHeadline}
              trimTextBottomMargin={trimSubheadlineBottomMargin}
              isSubHeadline={true}
              variant={variant}
              className="hero-content-subheadline"
            />
          )}
          {tags && <HeroTags tags={tags} />}
          {iconList ? <IconList iconList={iconList} /> : null}
        </Fragment>
      )}
      {footerEl}
    </Flex>
  );
}
