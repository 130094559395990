/** @jsx jsx */
import { Box, Flex, jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import HeroMedia from '../../heroTemplate/heroComponents/HeroMedia';
import { CallToAction } from '@sprinklr/shared-lib/components/cta';
import PlayIcon from '@sprinklr/shared-lib/assets/svgs/videoPlayer.svg';

const HeroFullBleedMedia = ({
  sourceType,
  source,
  videoSource,
  altTitle,
  name,
  customVariant,
  hasDarkBackground,
  isVideoCaptionDisabled,
}: {
  videoSource?: string;
  sourceType: string;
  source: Image;
  altTitle?: string;
  name: string;
  customVariant?: string;
  hasDarkBackground?: boolean;
  isVideoCaptionDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  if (sourceType === 'IMAGE' && source) {
    return (
      <HeroMedia
        mediaType="IMAGE"
        source={source}
        imageAlignment={'RIGHT'}
        hideImageOnMobile={true}
        hideImageOnTablet={false}
        imageShouldNotCover={false}
        imageShouldHaveDropShadow={true}
        altTitle={source?.description || altTitle || name}
        hasTextContent={true}
        disableMaxHeight={true}
        heroMediaSx={{
          marginBottom: [0, 0, 0, 0],
          borderRadius: [1],
          '.gatsby-image-wrapper': {
            borderRadius: 1,
            boxShadow: 3,
          },
        }}
      />
    );
  }

  if (sourceType === 'VIDEO' && videoSource) {
    // Hacky positioning tricks for Hyatt homepage - 6/21/21 - maybe refactor for future & don't make this default
    let height = ['150px', '300px', '100%'];
    let alignItems = ['center', 'flex-end', 'center'];
    if (customVariant === 'ragyPhoto') {
      height = ['100%'];
      alignItems = ['center'];
    }

    return (
      <Flex
        sx={{
          justifyContent: 'center',
          width: '100%',
          height: height,
          alignItems: alignItems,
        }}
      >
        <CallToAction
          action={'PLAY_VIDEO'}
          url={videoSource}
          type="BUTTON"
          ctaSx={{ borderBottom: 0 }}
          variant="unstyled"
          // TODO: Need translations & copy
          ariaLabel={t('Play video')}
          isVideoModalCaptionDisabled={isVideoCaptionDisabled}
        >
          <Box
            sx={{
              width: ['40px', '66px'],
              height: ['40px', '66px'],
              position: ['relative'],
            }}
          >
            <Box
              sx={{
                // SVG does not accept backdrop-filter properties so need to style div to look like the ellipse
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: '50%',
                backgroundColor: hasDarkBackground
                  ? 'rgba(255, 255, 255, 0.5)'
                  : 'rgba(0,0,0,1)',
                backdropFilter: 'blur(10px)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '& + svg': {
                    path: {
                      fill: '#ffffff',
                    },
                  },
                },
              }}
            />
            <PlayIcon
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                path: { fill: hasDarkBackground ? '#000000' : '#ffffff' },
                bottom: 0,
                pointerEvents: 'none',
                ellipse: {
                  fill: 'none',
                },
              }}
            />
          </Box>
        </CallToAction>
      </Flex>
    );
  }

  return null;
};

export default HeroFullBleedMedia;
