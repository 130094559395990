/** @jsx jsx */
import loadable from '@loadable/component';
import { ReactElement } from 'react';
import { jsx } from 'theme-ui';

import { IconTombstone } from '@sprinklr/shared-lib/components/IconTombstone';

import { ICON_DIMENSIONS } from './styles';

const getIconStyles = ({ color }: { color: string }) => ({
  ...ICON_DIMENSIONS,
  path: { fill: color },
});

const ICONS_MAP = {
  TickCircle: loadable(
    () => import('@sprinklr/shared-lib/assets/svgs/tick-circle.svg'),
  ),
};

export const Icon = ({ name }: { name: string }): ReactElement => {
  const IconComp = ICONS_MAP[name];
  return Icon ? (
    <IconComp
      sx={getIconStyles({ color: 'success' })}
      fallback={<IconTombstone sx={ICON_DIMENSIONS} />}
    />
  ) : null;
};
