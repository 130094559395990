export const TAG_STYLES = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: 2,
  border: '1px',
  borderStyle: 'solid',
  borderColor: 'success',
  borderRadius: '100px',
  paddingY: '8px',
  paddingX: '12px',
};

export const TAG_TEXT_STYLES = {
  fontSize: [1, 1],
  color: 'success',
  fontWeight: 'semiBold',
  marginBottom: 0,
};

export const ICON_DIMENSIONS = {
  width: '16px',
  height: '16px',
};
