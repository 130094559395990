import { Icon } from '@sprinklr/shared-lib/components/icon';
import React from 'react';
import { Flex, Box, ThemeUICSSObject } from 'theme-ui';
import { IconListItem } from '../types';

const ICON_LIST_STYLES: ThemeUICSSObject = { mt: '50px', gap: '50px' };

const ICON_LIST_ITEM_STYLES: ThemeUICSSObject = {
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
};
const ICON_LIST_ITEM_TITLE_STYLES: ThemeUICSSObject = {
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: 700,
  letterSpacing: '0.005em',
  color: 'sprText02',
};

const IconList = ({ iconList }: { iconList: IconListItem[] }) => {
  return (
    <Flex sx={ICON_LIST_STYLES}>
      {iconList?.map(iconItem => (
        <IconListElement key={iconItem.title} {...iconItem} />
      ))}
    </Flex>
  );
};

const IconListElement = ({ title, icon }: IconListItem) => {
  return (
    <Flex sx={ICON_LIST_ITEM_STYLES}>
      <Icon iconName={icon?.icon} />
      <Box sx={ICON_LIST_ITEM_TITLE_STYLES}>{title}</Box>
    </Flex>
  );
};

export default IconList;
