/** @jsx jsx */
import React, { Fragment } from 'react';
import {
  setContainerStyles,
  setSubContainerStyles,
  getGridStyles,
  setMediaContainerStyles,
  settextAlign,
  setBackgroundImageStyles,
} from './styles';
import { Flex, Grid, jsx, Box } from 'theme-ui';
import HeroContent from '../heroTemplate/heroComponents/HeroContent';
import { BackgroundImage, Container, ImageBlock } from '@sprinklr/shared-lib';
import { HeroFullBleedTemplateProps } from './types';
import HeroFullBleedMedia from './components/HeroFullBleedMedia';

import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

const HeroFullBleedTemplate: React.FC<HeroFullBleedTemplateProps> = props => {
  const {
    altTitle,
    backgroundImage,
    categoryLabelRichText,
    ctas,
    customVariant,
    hasBackgroundGradient,
    hasDarkBackground,
    heroHeadline,
    name,
    source,
    sourceType,
    heroSubHeadline,
    videoSource,
    topMargin,
    bottomMargin,
    isVideoCaptionDisabled,
    heroContentHtml = null,
    heroMobileSubHeadline,
  } = props;

  const padding = {
    top: topMargin ?? 'LARGE',
    bottom: bottomMargin ?? 'LARGE',
  };
  const [pt, pb] = useContainerPadding(padding);

  const isSingleHeroCentered = customVariant === 'singleHeroCentered';

  const shouldDisplayMedia =
    !isSingleHeroCentered &&
    (!!(sourceType === 'IMAGE' && source) ||
      !!(sourceType === 'VIDEO' && videoSource));
  const bgImageStyles = setBackgroundImageStyles(customVariant);

  return (
    <Box
      sx={{ position: 'relative', width: '100%', zIndex: 0, ...bgImageStyles }}
    >
      {backgroundImage ? (
        <BackgroundImage
          image={backgroundImage?.asset}
          tabletImage={backgroundImage?.tabletAsset}
          mobileImage={backgroundImage?.mobileAsset}
        />
      ) : null}
      <Flex
        sx={setContainerStyles(
          hasBackgroundGradient,
          backgroundImage,
          customVariant,
          hasDarkBackground,
          pt,
          pb,
        )}
      >
        <Container containerSx={setSubContainerStyles(customVariant)}>
          <Grid sx={getGridStyles(sourceType, customVariant)}>
            <HeroContent
              heroContentHtml={heroContentHtml?.heroContentHtml}
              headline={heroHeadline}
              subHeadline={heroSubHeadline}
              ctas={ctas}
              isCascadedHeroContent={true}
              categoryLabelRichText={categoryLabelRichText}
              contentFlex={1}
              textAlignment={settextAlign(customVariant)}
              isSingleHeroCentered={isSingleHeroCentered}
              mobileSubHeadline={heroMobileSubHeadline}
            />
            {shouldDisplayMedia && (
              <Flex sx={setMediaContainerStyles(sourceType)}>
                <HeroFullBleedMedia
                  sourceType={sourceType}
                  customVariant={customVariant}
                  source={source}
                  hasDarkBackground={hasDarkBackground}
                  altTitle={altTitle || name}
                  videoSource={videoSource}
                  name={name}
                  isVideoCaptionDisabled={isVideoCaptionDisabled}
                />
              </Flex>
            )}
          </Grid>
        </Container>
      </Flex>
    </Box>
  );
};

export default HeroFullBleedTemplate;
