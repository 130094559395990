/** @jsx jsx */
import { ReactElement } from 'react';
import { Flex, jsx, Themed } from 'theme-ui';
import { Icon } from './Icon';
import { Tag } from '../../types';
import { TAG_STYLES, TAG_TEXT_STYLES } from './styles';

const HeroTag = ({ tag }: { tag: Tag }): ReactElement => {
  const { text, icon } = tag;

  return (
    <Flex sx={TAG_STYLES}>
      <Icon name={icon} />
      <Themed.h6 sx={TAG_TEXT_STYLES}>{text}</Themed.h6>
    </Flex>
  );
};

export const HeroTags = ({ tags }: { tags?: Tag[] }): ReactElement => (
  <Flex
    sx={{
      gap: '10px',
      flexWrap: 'wrap',
      justifyContent: ['center', null, null, 'start'],
      marginTop: [0, '16px', '16px'],
    }}
  >
    {tags.map(tag => (
      <HeroTag key={tag.text} tag={tag} />
    ))}
  </Flex>
);
