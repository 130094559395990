/** @jsx jsx */
import { ReactElement } from 'react';
import { Box, ThemeProvider, jsx, ThemeUIStyleObject } from 'theme-ui';
import { RichText } from '@sprinklr/shared-lib';
import { useResponsiveValue } from '@theme-ui/match-media';
import { RichTextObject } from '@sprinklr/shared-lib/@types/entities';
import { HeroTemplateVariants } from '../types';

export default function HeroHeadlines({
  text,
  mobileText,
  isSubHeadline,
  isXLHeadlineSize,
  trimTextBottomMargin,
  variant,
  className,
}: {
  text: RichTextObject;
  mobileText?: RichTextObject;
  isSubHeadline?: boolean;
  isXLHeadlineSize?: boolean;
  trimTextBottomMargin?: boolean;
  variant?: HeroTemplateVariants;
  className?: string;
}): ReactElement {
  const containerStyles =
    isSubHeadline && variant !== 'HEADLINES_COMPACT'
      ? { marginTop: [0, '16px', '16px'] }
      : {};

  const content = useResponsiveValue([mobileText || text, text]);
  const headlineStyles: ThemeUIStyleObject = {
    fontWeight: ['bold'],
    lineHeight: isXLHeadlineSize ? '1.3em' : ['30px', '56px', '56px'],
    fontSize: isXLHeadlineSize ? ['36px', '60px'] : ['24px', '43px'],
    ...(trimTextBottomMargin && {
      marginBottom: 0,
    }),
  };
  const textStyles = isSubHeadline
    ? {
        styles: {
          p: {
            maxWidth: variant === 'SUBHEADLINE-MAX-WIDTH' && '780px',
            fontSize: [2, 4],
            lineHeight: ['23px', '30px'],
            ...(trimTextBottomMargin && {
              '&:last-child': {
                marginBottom: 0,
              },
            }),
          },
          h5: {
            a: {
              textDecoration: 'none',
            },
          },
        },
      }
    : {
        styles: {
          h1: headlineStyles,
          h2: headlineStyles,
        },
      };

  return (
    <Box sx={containerStyles}>
      <ThemeProvider theme={textStyles}>
        <Box className={className}>
          <RichText richTextObject={content} />
        </Box>
      </ThemeProvider>
    </Box>
  );
}
