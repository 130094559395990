import { ThemeUIStyleObject } from 'theme-ui';

const BACKGROUND_GRADIENT = {
  background: [
    'linear-gradient(180deg, rgba(195,209,230,0.8) 0%, rgba(236,241,247,1) 50%, rgba(244,246,250,1) 100%), #F4F6FA',
    null,
    null,
    'linear-gradient(142deg, rgba(195,209,230,0.8) 0%, rgba(236,241,247,1) 50%, rgba(244,246,250,1) 100%), #F4F6FA',
  ],
};

const setBackgroundImageStyles = customVariant => {
  return {
    '.gatsby-image': {
      objectPosition:
        customVariant === 'ragyPhoto'
          ? [
              'center !important',
              'top !important',
              'top !important',
              'top !important',
            ]
          : [
              'center bottom !important',
              'center bottom !important',
              'center bottom !important',
              'center !important',
            ],
    },
  };
};

const setContainerStyles =
  (
    hasBackgroundGradient,
    backgroundImage,
    customVariant,
    hasDarkBackground,
    pt,
    pb,
  ) =>
  theme => {
    let backgroundGradient;
    if (hasBackgroundGradient && !backgroundImage) {
      backgroundGradient = BACKGROUND_GRADIENT;
    }

    const defaultStyles = {
      width: '100%',
      pt,
      pb,
      justifyContent: 'center',
      overflow: 'hidden',
      ...backgroundGradient,
      '.hero-category-label, .stat-attribution': {
        color:
          hasBackgroundGradient && !backgroundImage && '#6C7383 !important',
      },
      'h1, h2, h3, h4, h5, h6, p, li, a, a:hover': {
        color: hasDarkBackground && '#ffffff',
      },
      'a:not(:hover)': {
        textDecoration: hasDarkBackground && 'none',
      },
    };

    switch (customVariant) {
      case 'ragyPhoto':
        return {
          ...backgroundGradient,
          width: '100%',
          height: [
            'calc(100vh - 66px)',
            'calc(100vh - 96px)',
            null,
            'calc(100vh - 125px)',
          ],
          justifyContent: 'center',
          overflow: 'hidden',
          h1: {
            fontSize: ['33px', null, null, '60px'],
            lineHeight: ['45px', null, null, '80px'],
          },
          maxHeight: ['640px', '660px', '920px'],
          minHeight: '600px',
          'h1, h2, h3, h4, h5, h6, p, li, a, a:hover': {
            color: hasDarkBackground && '#ffffff',
          },
          'a:not(:hover)': {
            textDecoration: hasDarkBackground && 'none',
          },
        };
      case 'singleHeroCentered': {
        return {
          ...backgroundGradient,
          width: '100%',
          padding: '0px',
          pt: ['0px', '0px', '0px', '120px'],
          pb: ['0px', '0px', '0px', '120px'],
          justifyContent: 'center',
          overflow: 'hidden',
          '.hero-category-label': {
            fontSize: ['12px', '14px', null, '16px'],
            lineHeight: ['12px', '14px', null, '16px'],
            marginBottom: '24px',
            color: theme.colors.text,
            fontWeight: 500,
          },
          '.hero-content-headline h2': {
            fontSize: ['24px', '28px', '36px', '46px'],
            fontWeight: 900,
            lineHeight: ['24px', '28px', '36px', '46px'],
            marginBottom: '12px',
            letterSpacing: ['3.6px', null, '9px'],
            color: '#00bae9',
          },
          '.hero-content-headline h4': {
            fontSize: ['20px', null, '31px', '34px'],
            fontWeight: 400,
            lineHeight: ['20px', null, '31px', '34px'],
          },
          '.hero-content-headline h6': {
            fontSize: ['18px', '18px', null, '24px'],
            lineHeight: ['18px', '18px', null, '24px'],
            color: theme.colors.text,
            fontWeight: 500,
            marginBottom: '0px',
            maxWidth: ['330px', null, '650px'],
            letterSpacing: ['0.9px', null, '1.5px'],
          },
          '.hero-content-subheadline h2': {
            fontSize: ['18px', null, '36px', '44px'],
            fontWeight: 800,
            lineHeight: ['18px', null, '36px', '44px'],
            textUnderlineOffset: ['5px', null, '10px', '12px'],
          },
          '.hero-content-headline': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        };
      }

      case 'detailedHero': {
        return {
          ...defaultStyles,
          paddingY: ['0px', '0px', '0px', '200px !important'],
          '.hero-content-headline p': {
            fontSize: ['12px', '14px', null, '18px'],
            marginBottom: '0px',
          },
          '.hero-content-subheadline h4': {
            textAlign: 'left',
            fontSize: ['22px', '36px'],
            lineHeight: ['26px', '40px'],
            marginTop: '40px',
            marginBottom: '0px',
          },
          '.hero-content-subheadline h5': {
            fontSize: ['14px', '16px', null, '18px'],
            lineHeight: ['20px', '22px', null, '24px'],
            marginBottom: '28px',
            marginTop: '14px',
            fontWeight: 'normal',
            textAlign: 'left',
          },
          '.hero-content-subheadline h6': {
            fontSize: ['14px', '16px', null, '18px'],
            lineHeight: ['20px', '22px', null, '24px'],
            marginBottom: '28px',
            fontWeight: 'normal',
          },
          '.hero-content-subheadline p': {
            fontSize: ['14px', '16px', null, '18px'],
            lineHeight: ['20px', '24px', null, '28px'],
            marginBottom: '0px',
          },
          '.hero-content-subheadline ul': {
            paddingLeft: '0px',
            paddingTop: '20px',
            color: '#00bae9',
          },
          '.hero-content-subheadline li': {
            marginBottom: ['10px', null, '18px'],
            textAlign: 'left',
          },
        };
      }

      default:
        return defaultStyles;
    }
  };

const settextAlign = customVariant => {
  switch (customVariant) {
    case 'ragyPhoto':
      return 'initial';
    default:
      return null;
  }
};

const setSubContainerStyles = customVariant => {
  switch (customVariant) {
    case 'ragyPhoto':
      return { py: ['40px', '80px', '80px', '140px'] };
    case 'noSourceLgBottomPadding':
      return {
        pt: ['60px', '90px', '90px', '0px'],
        pb: ['275px', '465px', '430px', '0px'],
      };
    default:
      return {
        pt: ['60px', '90px', '90px', '0px'],
        pb: ['60px', '100px', '200px', '0px'],
      };
  }
};

const getGridStyles = (sourceType, customVariant): ThemeUIStyleObject => {
  const defaultStyles: ThemeUIStyleObject = {
    textAlign: 'center',
    gridTemplateColumns: [
      '1fr',
      null,
      null,
      sourceType === 'IMAGE' ? 'repeat(2, 50%)' : 'repeat(2, 1fr)',
    ],
    gridGap: [sourceType === 'VIDEO' ? 5 : 0, 5],
    alignItems: 'center',
  };
  switch (customVariant) {
    case 'ragyPhoto':
    case 'bgWithHeroMedia':
      return {
        height: '100%',
        gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
        gridTemplateRows: ['repeat(2, 1fr)', '1fr'],
        gridGap: [5],
        alignItems: 'center',
        '& > div:first-child': {
          alignSelf: 'start',
        },
      };
    case 'singleHeroCentered':
      return {
        height: '100%',
        textAlign: 'center',
        gridTemplateColumns: '1fr',
        alignItems: 'center',
      };

    case 'detailedHero':
      return {
        ...defaultStyles,
        gridGap: 0,
      };
    case 'leftAligned': {
      return {
        ...defaultStyles,
        gridTemplateColumns: ['1fr', null, null, 'repeat(2, 1fr)'],
        maxWidth: '1360px',
        width: 'calc(100vw - 160px)',
      };
    }
    case 'rightAligned': {
      return {
        ...defaultStyles,
        gridTemplateColumns: ['1fr', null, null, 'repeat(2, 50%)'],
        maxWidth: '1360px',
        width: 'calc(100vw - 160px)',
        '& > :nth-child(1)': {
          gridColumnStart: [1, null, null, 2],
          gridRowStart: [2, null, null, 1],
        },
        '& > :nth-child(2)': {
          gridColumnStart: [1, null, null, 1],
          gridRowStart: [1, null, null, 1],
        },
      };
    }
    default:
      return defaultStyles;
  }
};

const setMediaContainerStyles = sourceType => {
  let width;
  if (sourceType === 'IMAGE') width = ['100%', null, null, '860px'];
  if (sourceType === 'VIDEO') width = ['100%'];

  let display;
  if (sourceType === 'IMAGE') display = ['none', 'block'];
  if (sourceType === 'VIDEO') display = 'block';

  let mt;
  if (sourceType === 'VIDEO') mt = ['0px', '0px', '100px', '0px'];

  return {
    display: display,
    width: width,
    mt: mt,
  };
};

export {
  setContainerStyles,
  setMediaContainerStyles,
  settextAlign,
  setSubContainerStyles,
  getGridStyles,
  setBackgroundImageStyles,
};
