/** @jsx jsx */
import { ReactElement } from 'react';
import { Image, RichTextObject } from '@sprinklr/shared-lib/@types/entities';
import { jsx, ThemeUIStyleObject, Box } from 'theme-ui';
import { Media } from '@sprinklr/shared-lib';

import loadable from '@loadable/component';

import { Carousel as CarouselType } from '../types';

const Carousal = loadable(() => import('../carousal'));

type MediaType = 'IMAGE' | 'VIDEO' | 'CAROUSEL';

type Props = {
  mobileImage?: Image;
  hideImageOnMobile?: boolean;
  hideImageOnTablet?: boolean;
  imageAlignment?: 'LEFT' | 'CENTER' | 'RIGHT';
  imageCaption?: RichTextObject;
  mediaType: MediaType;
  source?: Image;
  externalVideoUrl?: string;
  previewImage?: Image;
  altTitle?: string;
  loopVideo?: boolean;
  imageShouldNotCover?: boolean;
  imageShouldHaveDropShadow?: boolean;
  hasTextContent?: boolean;
  reverseStackingOrder?: boolean;
  isImgEager?: boolean;
  disableMaxHeight?: boolean;
  heroMediaSx?: ThemeUIStyleObject;
  imageSx?: ThemeUIStyleObject;
  carousel?: CarouselType;
};

export default function HeroMedia({
  mediaType,
  source,
  externalVideoUrl,
  previewImage,
  imageAlignment,
  imageCaption,
  mobileImage,
  hideImageOnMobile,
  hideImageOnTablet,
  imageShouldNotCover,
  imageShouldHaveDropShadow,
  altTitle,
  loopVideo,
  hasTextContent,
  isImgEager,
  disableMaxHeight,
  heroMediaSx,
  imageSx,
  carousel,
}: Props): ReactElement {
  return mediaType === 'CAROUSEL' ? (
    <Carousal carousel={carousel} />
  ) : (
    <Media
      mediaType={mediaType}
      source={source}
      externalVideoUrl={externalVideoUrl}
      previewImage={previewImage}
      imageAlignment={imageAlignment}
      imageCaption={imageCaption}
      isImgEager={isImgEager}
      mobileImage={mobileImage}
      hideImageOnMobile={hideImageOnMobile}
      hideImageOnTablet={hideImageOnTablet}
      altTitle={altTitle}
      loopVideo={loopVideo}
      hasTextContent={hasTextContent}
      carousel={carousel}
      mediaSx={{
        justifyContent: 'center',
        marginBottom: [0],
        marginTop: [0],
        borderRadius: [2],
        '.gatsby-image-wrapper': {
          maxHeight: disableMaxHeight ? ['100%'] : ['200px', '300px'],
          borderRadius: 2,
          boxShadow: imageShouldHaveDropShadow ? 4 : null,
          // TODO: Figure out how to do this the "ThemeUI way"
          '@media only screen and (min-width: 1025px) and (max-width: 1440px)':
            {
              maxHeight: imageCaption ? '510px' : '530px',
            },
          '@media only screen and (min-width: 1441px)': {
            maxHeight: '600px',
          },
          img: {
            // TODO: Figure out how to make this work using specificity instead of !important
            objectFit: imageShouldNotCover
              ? ['contain !important']
              : ['cover !important'],
            minHeight: '100%',
            minWidth: '100%',
          },
          ...imageSx,
        },
        ...heroMediaSx,
      }}
      videoSx={{
        maxWidth: '100%',
        height: 'auto',
        maxHeight: ['200px', '300px'],
        // TODO: Figure out how to do this the "ThemeUI way"
        '@media only screen and (min-width: 1025px) and (max-width: 1440px)': {
          maxHeight: imageCaption ? '510px' : '530px',
        },
        '@media only screen and (min-width: 1441px)': {
          maxHeight: '600px',
        },
        'video, .react-player__preview, .react-player-wrapper, .react-player-wrapper video':
          {
            // Going nuclear because of package hierarchy issues
            boxShadow: 'none !important',
          },
      }}
      videoHeight={[200, 300, null, '100%']}
      videoWidth={[315, 480, 500, '100%']}
    />
  );
}
